<template>
  <div class="info">
    <top-bar :title="'新增问题'" :left="true"></top-bar>

    <van-popup v-model="CommunityShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
    </van-popup>

    <van-popup v-model="positionShow"   position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.positionList" value-key="label"  @confirm="positionConfirm"  @cancel="positionShow = false"/>
    </van-popup>

    <van-popup v-model="BuildingShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
    </van-popup>

    <van-popup v-model="unitShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
    </van-popup>

    <van-popup v-model="houseShow"   position="bottom"  >
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到房屋信息,请先选择单元或切换单元</van-notice-bar>
      <van-picker title="请选择" show-toolbar :columns="selectData.houseList" value-key="label"  @confirm="houseConfirm"  @cancel="houseShow = false"/>
    </van-popup>

    <van-popup v-model="CommunityShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
    </van-popup>

    <van-popup v-model="timerShow"  position="bottom"  >
      <van-datetime-picker
          @confirm="timerConfirm"
          type="datetime"
          title="选择完整时间"
          :min-date="minTimer"
          :max-date="maxTimer"
      />
    </van-popup>

    <van-popup v-model="stereotypeShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.stereotypeList" value-key="label" @confirm="stereotypeConfirm"  @cancel="stereotypeShow = false"/>
    </van-popup>

    <van-popup v-model="evnetTypeShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.evnetTypeList" value-key="label" @confirm="evnetTypeConfirm"  @cancel="evnetTypeShow = false"/>
    </van-popup>

    <van-popup v-model="isDisposeShow"  position="bottom"  >
      <van-picker title="请选择" show-toolbar :columns="selectData.isDisposelist" value-key="label" @confirm="isDisposeConfirm"  @cancel="isDisposeShow = false"/>
    </van-popup>

    <van-cell-group>
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">问题信息</span>
        </template>
      </van-cell>
      <van-cell title="事件标题" >
        <van-field readonly v-model="selectMsg.title" placeholder="请输入" style="text-align: right;"/>
      </van-cell>
      <van-cell title="所在小区" :value="selectMsg.Community" />
      <van-cell title="所在位置" :value="selectMsg.position" />
      <van-cell title="具体位置"  v-if="submitData.location == 1">
        <van-field readonly v-model="selectMsg.address" placeholder="请输入" style="text-align: right;"/>
      </van-cell>
      <van-cell title="所在楼幢" :value="selectMsg.Building"   v-if="selectMsg.position == '室内楼幢'"/>
      <van-cell title="所在单元" :value="selectMsg.unit" v-if="selectMsg.position == '室内楼幢' && divideUnit === '1'"/>
      <van-cell title="所在房屋" :value="selectMsg.house"  v-if="selectMsg.position == '室内楼幢' && divideHouse === '1'"/>
      <van-cell title="事件时间" :value="selectMsg.timer" />
      <van-cell title="事件定型分类" :value="selectMsg.stereotype" />
      <van-cell title="事件分类" :value="selectMsg.evnetType"/>
      <van-cell title="是否自行处理" :value="selectMsg.isDispose" />
      <van-field v-model="selectMsg.description" rows="3" autosize readonly :border="false" type="textarea" placeholder="这是一条问题描述" class="left"/>
      <van-row class="uploadBox">
        <van-col :span="24">
          <van-uploader v-model="fileList" disabled :show-upload="false" :deletable="false"/>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">核实信息</span>
        </template>
      </van-cell>
      <van-cell title="事件地区:" is-link :value="community" @click="CommunityShow = true" :value-class="{'value-common':!community}"/>
      <van-field v-model="content" rows="3" :border="false" autosize type="textarea" placeholder="核实内容" class="left"/>
      <van-row class="uploadBox">
        <van-col :span="24">
          <van-uploader v-model="verifyFile" @delete="uploaddel" :after-read="uploadRead"/>
        </van-col>
      </van-row>
    </van-cell-group>

    <van-row class="btns">
      <van-col :span="24" >
        <van-button type="info" size="large" round @click="submit">完成</van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {getbelongSubArea, getDictTree, getVirtualDict} from '@/utils/common'

import { mapState } from 'vuex'
import {getImageStream} from "@/utils";
import {newDate, formatterDateTimeWithOutSec} from "@/utils/utils";
export default {
  data () {
    return {
      verifyFileList: [],
      verifyFile: [],
      content: '',
      community: '',
      file:{},
      detailData: {},
      categoryValue: '',
      problemId: '',
      categoryshow: false,
      CommunityShow: false,
      positionShow: false,
      BuildingShow: false,
      unitShow: false,
      houseShow: false,
      stereotypeShow: false,
      timerShow: false,
      isDisposeShow: false,
      evnetTypeShow: false,
      divideHouse: '1',
      divideUnit: '1',
      maxTimer: new Date(),
      minTimer: new Date(2017, 1, 1),
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      selectMsg: {
        title: '',
        category: '请选择',
        Community: '请选择',
        position: '请选择',
        Building: '请选择',
        house: '请选择',
        unit: '请选择',
        timer: '请选择',
        stereotype: '请选择',
        evnetType: '请选择',
        description: '',
        address: '',
        isDispose: '请选择'
      },
      submitData: {
        building:'',
        content:'',
        fileMessages:[],
        newFileMessages:[],
        deleteFiles: [],
        house:'',
        location:'',
        orgId:0,
        subarea:'',
        type:'',
        unit:'',
        userId:'',
        stereotype: '',
        evnetType: [],
        isDispose: ''
      },
      selectData: {
        categoryList: [],
        CommunityList: [],
        positionList: [{ label: "公共区域", value: "1" }, { label: "室内楼幢", value: "2" }],//, { label: "高层建筑", value: "2" }
        BuildingList: [],
        unitList: [],
        houseList: [],
        timerList: [],
        stereotypeList: [],
        evnetTypeList: [],
        isDisposelist: [{label: '是', value: '1'}, {label: '否', value: '0'}]
      },
      fileList: [],
    }
  },
  components :{
    topBar
  },
  methods: {
    // 删除图片
    uploaddel (file, detail) {
      let i = detail.index
      this.verifyFileList.splice(i, 1)
    },
    // 上传图片
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/problem/reported/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data})=> {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.verifyFileList.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    init () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.CommunityList = data.subAreaList
        } else {
          this.$toast.fail(data.msg);
        }
      })
      getDictTree({code: 'problemReportedType'},  (data) => {
        this.selectData.evnetTypeList = data
      })
      getVirtualDict('stereoTypesCate',  (data) => {
        this.selectData.stereotypeList = data
      })
      // 获取详情
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/reported/info'),
        method: 'post',
        params: this.$httpApp.adornParams({
          problemId: this.problemId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          let Building = data.problemReported.buildingStr || '请选择'
          let unit = data.problemReported.unitStr || '请选择'
          let house = data.problemReported.houseStr || '请选择'
          let stereotype = this.selectData.stereotypeList.map(item => {
            if (item.value == data.problemReported.stereoTypesCate) {
              return item.label
            }
          }).join('')
          this.community = data.problemReported.subareaStr || ''
          this.selectMsg = {
            title: data.problemReported.subject,
            Community: data.problemReported.subareaStr || '',
            position: data.problemReported.placeType == 2 ? '室内楼幢' : '公共区域',
            Building: Building,
            unit: unit,
            house: house,
            description: data.problemReported.content || '',
            timer: data.problemReported.occurDate || '请选择',
            stereotype: stereotype,
            evnetType: data.problemReported.thirdCategoryDictStr || '请选择',
            address: data.problemReported.occurLocation,
            isDispose: data.problemReported.isDispose
          }
          this.submitData = {
            building:data.problemReported.buildingId || '',
            content:data.problemReported.content || '',
            house:data.problemReported.houseId || '',
            location:data.problemReported.placeType == 2 ? '2' : '1',
            orgId:this.$orgId,
            deleteFiles: [],
            fileMessages:data.problemReported.reportedFileList || [],
            id: this.problemId,
            subarea:data.problemReported.orgIds[data.problemReported.orgIds.length - 1] || '',
            type:data.problemReported.type || '',
            unit:data.problemReported.unitId || '',
            roomUse: data.problemReported.roomUse || '',
            userId:this.$globalData.userInfo.userId || '',
            stereotype: '',
            evnetType: [],
            isDispose: ''
          }
          if (data.problemReported.reportedFileList && data.problemReported.reportedFileList.length != 0) {
            data.problemReported.reportedFileList.forEach(item=> {
              let u;
                u = getImageStream(item.relativePath)

                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: item.fileType,
                  url: u,
                  relativePath: item.relativePath
                }
                this.fileList.push(url);
            })
          }
          if (data.problemReported.placeType == 2) {
            let value = {name: data.problemReported.subareaStr,id: data.problemReported.orgIds[data.problemReported.orgIds.length - 1]}
            let ids = {
              building: {value: data.problemReported.buildingId, label: Building},
              unit: {value: data.problemReported.unitId, label: unit},
              house: {value: data.problemReported.houseId, label: house}
            }
            this.CommunityConfirm(value, true, ids)
          }
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg);
        }
      })
    },
    submit () {
      console.log(this.verifyFileList)
      console.log(this.submitData)
      if (this.content.trim() === '') {return this.$toast.fail('失败:请先输入核实内容');}
      if (this.verifyFileList.length < 1) {return this.$toast.fail('失败:请先上传核实图片');}
      this.$toast.loading({duration: 0,message: '提交中...',forbidClick: true,});
      this.$httpApp({
        url: this.$httpApp.adornUrl('/wxapp/problem/eventVerify'),
        method: 'post',
        params: this.$httpApp.adornParams({
          problemId: this.problemId,
          content: this.content,
          orgId: this.submitData.subarea,
          fileUrl: this.verifyFileList.map(item => item.relativePath).join(',')
        })
      }).then(({data})=> {
        this.$toast.clear()
        if (data.code == 0) {
          this.$toast.success ('核实成功');
          this.$router.go(-1)
        } else {
          this.$toast.fail(data.msg);
        }
      },err=> {this.$toast.clear()})
    },
    // 小区选择
    CommunityConfirm (value, flag = false, ids = {}) {
      console.log(value);
      if (value) {
        this.community = value.name
        this.submitData.subarea = value.id
        // this.selectMsg.Community = value.name
        // this.selectMsg.position = '请选择'
        // this.selectMsg.Building = '请选择'
        // this.selectMsg.unit = '请选择'
        // this.submitData.unit = ''
        // this.selectData.unitList = []
        // this.selectData.houseList = []
        // this.selectMsg.house = '请选择'
        // this.submitData.house = ''
        // this.selectData.BuildingList = []
        // this.divideUnit = '1'
        // this.divideHouse = '1'
        // this.$http({
        //   url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        //   method: 'post',
        //   params: this.$http.adornParams({
        //     subArea: value.id
        //   },false)
        // }).then(({data})=> {
        //   if (data.code == 0) {
        //     this.selectData.BuildingList = data.buildings
        //     if (flag) {
        //       data.buildings.forEach(item => {
        //         if (item.value == ids.building.value) {
        //           ids.building['divideHouse'] = item.divideHouse
        //           ids.building['divideUnit'] = item.divideUnit
        //         }
        //       })
        //       this.selectMsg.position = '室内楼幢'
        //       this.selectMsg.location = '2'
        //       this.BuildingConfirm(ids.building, flag, ids)
        //     }
        //   } else {
        //     this.$toast.fail(data.msg);
        //   }
        // })
      }
      this.CommunityShow = false
    },
    // 当前位置选择
    positionConfirm (value) {
      if (value) {
        this.selectMsg.position = value.label
        this.submitData.location = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
      this.positionShow = false
    },
    // 楼栋选择
    BuildingConfirm (value, flag = false, ids = {}) {
      if (value) {
        this.selectMsg.Building = value.label
        this.submitData.building = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.divideHouse = value.divideHouse
        this.divideUnit = value.divideUnit
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
      if (this.divideUnit === '0') {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
          method: 'post',
          params: this.$http.adornParams({
            upId: value.value,
            bOru: 2
          },false)
        }).then(({data})=> {
          if (data.code == 0) {
            console.log(data)
            if (this.divideHouse === '0') {
              this.submitData.house =data.houseOptions[0].value
            } else {
              this.selectData.houseList = data.houseOptions
              if (flag) {
                this.houseConfirm(ids.house)
              }
            }
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
        method: 'post',
        params: this.$http.adornParams({
          buildingId: value.value
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.unitList = data.units
          if (flag) {
            this.unitConfirm(ids.unit, flag, ids)
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
      this.BuildingShow = false
    },
    // 单元选择
    unitConfirm (value, flag = false, ids = {}) {
      if(value) {
        this.selectMsg.unit = value.label
        this.submitData.unit = value.value
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
      }
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/listByUpId'),
        method: 'post',
        params: this.$http.adornParams({
          upId: value.value,
          bOru: 2
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          console.log(data);
          this.selectData.houseList = data.houseOptions
          if (flag) {
            this.houseConfirm(ids.house)
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
      this.unitShow = false
    },
    // 房屋选择
    houseConfirm (value) {
      if(value) {
        this.selectMsg.house = value.label
        this.submitData.house = value.value
      }
      this.houseShow = false
    },
    timerConfirm (value) {
      this.selectMsg.timer = formatterDateTimeWithOutSec(value)
      this.timerShow = false
    },
    stereotypeConfirm (value) {
      this.selectMsg.stereotype = value.label
      this.submitData.stereotype = value.value
      this.stereotypeShow = false
    },
    isDisposeConfirm (value) {
      this.selectMsg.isDispose = value.label
      this.submitData.isDispose = value.label
      this.isDisposeShow = false
    },
    evnetTypeConfirm (value, index) {
      this.selectMsg.evnetType = value[2]
      let arr = [this.selectData.evnetTypeList[index[0]].value, this.selectData.evnetTypeList[index[0]].children[index[1]].value, this.selectData.evnetTypeList[index[0]].children[index[1]].children[2].value]
      this.submitData.evnetType = arr
      this.evnetTypeShow = false
    },
    onCancel() {
    },
  },
  created () {
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.problemId = this.$route.query.id
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
